import moment from "moment";
import { Time } from '../components/types/index';

export const isTimeGreaterThanNow = (time: Time) => {
  return (
    moment(time.availableUntil.replace(/[+Z].*$/, '')).format(
      'YYYY-MM-DDTHH:mm:ss'
    ) >= moment().tz('Europe/Stockholm').format('YYYY-MM-DDTHH:mm:ss')
  );
};

export const isValidTime = (time: Time, guests: string,) => {
  return time.availableSeats.includes(guests) && isTimeGreaterThanNow(time);
}
