import style from './style.css';
import Content from '../../components/Content';
import { ThemeProvider } from '@material-ui/styles';
import { Typography, createTheme } from '@material-ui/core';
import { useEffect, useState } from 'preact/hooks';
import Close from '@material-ui/icons/Close';
import {
  AvalibleTimes,
  CaspecoHomeSettings,
  DayState,
  Params,
} from 'src/components/types';
import { Lang } from '../../components/types/index';
import homeAPI from '../../services/homeAPI';
import { getMessages } from '../../helpers/getLanguageData';
import { isValidLanguage } from '../../helpers/isValidLanguage';
import useBookingStore from '../../store/bookingStore';
import { Steps } from '../../components/types/bookingSteps';

let queryParams: URLSearchParams;
let params: Params;

try {
  queryParams = new URLSearchParams(window.location.search) ?? {};
} catch (e) {
  queryParams = {} as URLSearchParams;
}

try {
  params = JSON.parse(window.name) ?? {};
} catch (e) {
  params = {} as Params;
}

const acceptedLang = ['en', 'sv'];
const paramHash = queryParams.get('hash') ?? '';
const paramRestaurant = queryParams.get('r') ?? '';
const paramPromo = queryParams.get('p') ?? null;
const urlParamTheme = queryParams.get('theme') ?? 'dark';
const urlParamLang = isValidLanguage(queryParams.get('lang'));

interface HomeProps {
  path: string;
}

export default function Home({ }: HomeProps) {
  const { activeStep, skipped, setSkipped, validTimes, setActiveStep, resetState } =
    useBookingStore();
  const [isLoading, setIsLoading] = useState(true);
  const [lang, setLang] = useState<Lang | null>(null);
  const [locale, setLocale] = useState('');
  const [paramUnitId, setParamUnitId] = useState('');
  const [paramSystem, setParamSystem] = useState('');
  const [paramLang, setParamLang] = useState('');
  const [paramTheme, setParamTheme] = useState(urlParamTheme);
  const [paramsNoShow, setParamsNoShow] = useState<any>('');
  const [hideMessageForm, setHideMessageForm] = useState<boolean | null>(null);
  const [availableTimes, setAvailableTimes] = useState<AvalibleTimes[]>([]);
  const [caspecoSettings, setCaspecoSettings] = useState<CaspecoHomeSettings>();
  const [dayStates, setDayStates] = useState<DayState[]>([]);
  const [parentHeight, setParentHeight] = useState(790);

  useEffect(() => {
    if (paramHash !== '' || paramRestaurant !== '') {
      homeAPI
        .getMaitresInformation(paramHash, paramRestaurant, paramPromo)
        .then((data) => {
          if (data) {
            const {
              available_times,
              widget_params,
              widget_settings,
              day_states,
            } = data;

            setAvailableTimes(available_times);
            setCaspecoSettings(widget_settings);
            setParamUnitId(widget_params.unitId);
            setParamSystem(widget_params.system);
            setParamLang(widget_params.lang);
            setParamTheme(widget_params.theme);
            setParamsNoShow(widget_params.noshow);
            setLocale(acceptedLang.includes(paramLang) ? paramLang : 'sv');
            setHideMessageForm(widget_params.hide_message_form);
            setDayStates(day_states);
          }
        });
    } else {
      setParamUnitId(queryParams.get('unitId') ?? params.unitId ?? 13);
      setParamSystem(queryParams.get('system') ?? params.system ?? 'se_testbb');
      setParamLang(params.lang ?? acceptedLang[1]);
      setParamTheme(params.theme ?? 'dark');
      setParamsNoShow(params.noshow ?? []);
      setLocale(acceptedLang.includes(paramLang) ? paramLang : 'sv');
      setHideMessageForm(params.hide_message_form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramHash, paramRestaurant]);

  const theme = createTheme({
    palette: {
      type:
        paramTheme === 'dark'
          ? paramTheme
          : paramTheme === 'white'
            ? 'light'
            : 'dark',
      primary: {
        main: '#ADDCCF',
      },
    },
  });

  const getWidth = (step: number) => {
    switch (step) {
      case 0:
        return '150px';
      case 1:
        return '354px';
      default:
        return '354px';
    }
  };

  const getHeight = () => {
    if (activeStep === Steps.Bokaboard) {
      return '45px';
    } else if (activeStep === Steps.DateTimeSelector) {
      return '420px';
    } else if (activeStep === Steps.EventSelector) {
      return validTimes.length > 0 ? '680px' : '420px';
    } else {
      return '680px';
    }
  };

  useEffect(() => {
    const messageData = {
      type: 'from-app',
      height: getHeight(),
      width: getWidth(activeStep),
    };

    window.parent.postMessage(messageData, '*');
  }, [activeStep, parentHeight, validTimes]);

  useEffect(() => {
    const getParentData = (event: any) => {
      if (event.data.type === 'show-widget' && activeStep === 0) {
        setActiveStep(1);
        console.log("hererere!");
      }
    };

    window.addEventListener('message', getParentData);
    return () => {
      window.removeEventListener('message', getParentData);
    };
  }, []);

  useEffect(() => {
    if (locale !== '') {
      const data = getMessages(urlParamLang || locale);
      setLang(data);
      setSkipped([data.stepsEnum[900]]);
      setIsLoading(false);
    }
  }, [locale, paramLang, paramUnitId]);

  const themeElection =
    paramTheme === 'dark'
      ? paramTheme
      : paramTheme === 'white'
        ? 'light'
        : 'dark';

  const bodyElement = document.querySelector('body');
  if (bodyElement) {
    bodyElement.className = '';
    if (activeStep === 0) {
      bodyElement.classList.add('not-background');
    } else {
      bodyElement.classList.add(themeElection);
    }
  }

  const onCloseApp = () => {
    resetState();
  };

  return (
    <>
      {!isLoading && (
        <div className={activeStep === 0 ? '.not-backgorund ' : themeElection}>
          <div className={style.home} style={{ maxWidth: '354px' }}>
            {!isLoading && (
              <ThemeProvider theme={theme}>
                {activeStep !== 0 && (
                  <div className='header-container without-stepper transparent'>
                    <button
                      aria-label='button-close'
                      id='close-widget'
                      onClick={onCloseApp}
                    >
                      <Close fontSize='large' color='primary' />
                    </button>
                    <Typography
                      className='title-container'
                      style={{
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '18.4px',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        width: '278px',
                      }}
                    >
                      {availableTimes[0]?.name}
                    </Typography>
                  </div>
                )}
                <Content
                  hideMessageForm={hideMessageForm!}
                  lang={lang!}
                  locale={urlParamLang || locale}
                  skipped={skipped}
                  setSkipped={setSkipped}
                  paramUnitId={paramUnitId}
                  paramSystem={paramSystem}
                  paramsNoShow={paramsNoShow}
                  paramPromo={paramPromo!}
                  paramTheme={paramTheme}
                  availableTimes={availableTimes}
                  setAvailableTimes={setAvailableTimes}
                  caspecoSettings={caspecoSettings}
                  dayStates={dayStates}
                  setDayStates={setDayStates}
                />
              </ThemeProvider>
            )}
          </div>
        </div>
      )}
    </>
  );
}
