import { Box, Typography } from '@material-ui/core';
import { FunctionComponent, h } from 'preact';


interface NoEventPlaceHolderProps {
  text?: string;
}

const NoAvailableTimes: FunctionComponent<NoEventPlaceHolderProps> = ({
  text = "There are no events available for this date.",
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <Typography
        style={{
          fontSize: '14px',
          color: '#777',
          fontWeight: 300,
          textAlign: 'center',
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

export default NoAvailableTimes;
