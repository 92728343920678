import { h } from 'preact';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ListItemIcon, makeStyles } from '@material-ui/core';
import { StateUpdater, useMemo, useEffect, useState } from 'preact/hooks';
import constants from '../constants';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { AvalibleTimes, Lang } from './types';
import { Day } from './types/index';
import 'moment/dist/locale/sv';
import useBookingStore from '../store/bookingStore';
import { Steps } from './types/bookingSteps';

moment.locale('sv');

interface Locales {
  [key: string]: string;
}

const localeMap: Locales = {
  en: 'en',
  sv: 'sv',
};

interface MaterialUIPickersProps {
  dateBooking: string;
  setDateBooking: StateUpdater<string>;
  dayStates: Day[];
  availableTimes: AvalibleTimes[];
  lang: Lang;
  locale: string;
  setFromDate: StateUpdater<string>;
  setToDate: StateUpdater<string>;
  setActiveButtonTime: (time: string) => void;
}

export default function MaterialUIPickers({
  dateBooking,
  setDateBooking,
  dayStates,
  availableTimes,
  lang,
  locale,
  setFromDate,
  setToDate,
  setActiveButtonTime,
}: MaterialUIPickersProps) {
  useEffect(() => {
    moment.locale(locale);
  }, [locale]);
  const { activeStep, setActiveStep, setEventId } = useBookingStore();
  const [disableAll, setDisableAll] = useState(false)

  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
      '& .MuiTextField-root': {
        width:
          activeStep === Steps.EventSelector
            ? '98px'
            : activeStep === Steps.DateTimeSelector
              ? '320px'
              : '152px',
      },
      '&': {
        '& .MuiListItemIcon-root': {
          color: theme.palette.common.black,
          position: 'absolute',
          top: activeStep === Steps.DateTimeSelector ? '24px' : '15px',
          left: activeStep === Steps.DateTimeSelector ? '20px' : '5px',
        },
        '& .MuiInputBase-root': {
          paddingLeft: activeStep === Steps.DateTimeSelector ? '30px' : '20px',
        },
      },
    },
  }));

  const classes = useStyles();

  const disabledDates = useMemo(() => {
    setDisableAll(false)
    const disabledDays = dayStates.filter((day) => day.state <= 1);
    return disabledDays.map((day) => moment(day.day).toISOString());
  }, [dayStates]);

  const daysWithEvents = useMemo(() => {
    const filterdDays = dayStates.filter((day) => day.state > 1);
    return filterdDays.map((day) => moment(day.day).toISOString());
  }, [dayStates]);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    const naiveDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    const newDateBooking = moment.tz(naiveDate, constants.TIMEZONE).format();

    if (
      newDateBooking.split('T')[0] !== dateBooking.split('T')[0] &&
      activeStep === Steps.EventTimes
    ) {
      setEventId('');
      setActiveStep(Steps.EventSelector);
    }

    if (
      moment(dateBooking).tz(constants.TIMEZONE).format('YYYY-MM-DD') !==
      moment(date).format('YYYY-MM-DD')
    ) {
      setActiveButtonTime('');
      setDateBooking(newDateBooking);
    }
  };

  const disableWeekends = (date: MaterialUiPickersDate) => {
    const dates = moment(date).toISOString();

    return disableAll || disabledDates.includes(dates);
  };

  const onMonthChange = (date: MaterialUiPickersDate) => {
    setDisableAll(true)
    const startOfMonth = moment(date)
      .tz(constants.TIMEZONE)
      .format('YYYY-MM-DD');
    const endOfMonth = moment(date)
      .tz(constants.TIMEZONE)
      .endOf('month')
      .format('YYYY-MM-DD');

    setFromDate(startOfMonth);
    setToDate(endOfMonth);
  };

  const aux = moment(dateBooking)
    .tz(constants.TIMEZONE)
    .format('YYYY-MM-DD HH:mm:ss');

  const firstDayWithEvent = daysWithEvents.length > 0 ?
    moment(daysWithEvents[0])
      .tz(constants.TIMEZONE)
      .format('YYYY-MM-DD')
    : undefined;

  if (firstDayWithEvent && aux < firstDayWithEvent) {
    setDateBooking(firstDayWithEvent);
    handleDateChange(moment(firstDayWithEvent))
  }

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={localeMap[locale]}
      >
        <ListItemIcon>
          <CalendarTodayIcon fontSize='medium' color='primary' />
        </ListItemIcon>
        <DatePicker
          variant='inline'
          inputVariant='outlined'
          label={lang.date}
          value={moment
            .tz(aux, constants.TIMEZONE)
            .format('YYYY-MM-DD HH:mm:ss')}
          onChange={handleDateChange}
          shouldDisableDate={disableWeekends}
          minDate={moment().tz(constants.TIMEZONE).format('YYYY-MM-DD HH:mm')}
          format='MMM DD'
          error={availableTimes.length === 0}
          disableCloseOnSelect={false}
          onMonthChange={onMonthChange}
          autoOk={true}
          required
          timeZone={constants.TIMEZONE}
          onClose={() => {
            const startOfMonth = `${moment().clone().tz(constants.TIMEZONE).startOf("month").format("YYYY-MM-DD")}T00:00:00.000Z`;
            const endOfMonth = `${moment().clone().tz(constants.TIMEZONE).add(1, "month").endOf("month").format("YYYY-MM-DD")}T00:00:00.000Z`;
            setFromDate(startOfMonth);
            setToDate(endOfMonth);
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
