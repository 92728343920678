import moment from 'moment';
import styled from 'styled-components';
import { StateUpdater, useEffect, useState } from 'preact/hooks';
import Loader from '../Loader';
import ButtonTime from './ButtonTime';
import { AvalibleTimes, Lang, Section, TimeSet } from '../types';
import RestaurantCard from '../Card/RestaurantCard';
import useBookingStore from '../../store/bookingStore';
import ButtonsTimesContainer from '../ButtonsTimesContainer';
import { isValidTime } from '../../helpers/isTimeGreaterThanNow';

interface ButtonsSelectProps {
  setButtonTime: StateUpdater<string>;
  availableTimes: AvalibleTimes[];
  setButtonTimeEnd: StateUpdater<string>;
  guests: string;
  unitId: string;
  loadedTimes: boolean;
  setLoadedTimes: StateUpdater<boolean>;
  setRuleId: StateUpdater<string>;
  setRecoupTime: StateUpdater<number>;
  lang: Lang;
}

export default function ButtonsSelect({
  setButtonTime,
  availableTimes,
  setButtonTimeEnd,
  guests,
  unitId,
  loadedTimes,
  setLoadedTimes,
  setRuleId,
  setRecoupTime,
  lang,
}: ButtonsSelectProps) {
  const { eventId } = useBookingStore();
  const numberId = parseInt(eventId.toString(), 10);
  const [sections, setSections] = useState<Section[]>([]);
  const [filteredTimeSets, setFilteredTimeSets] = useState<TimeSet[]>([]);

  useEffect(() => {
    const mapSections: Section[] = [];

    if (availableTimes[0].id == unitId) {
      const sections = availableTimes[0].sections;
      sections.map((item) => {
        if (item.timeSets.length !== 0) {
          item.timeSets.map((timeSet) => {
            timeSet.times.map((time) => {
              time.id = timeSet.id;
            });
          });

          item.timeSets = item.timeSets.flatMap((current, i, array) => {
            const next = array[i + 1];
            if (next && current.title === next.title) {
              next.times = [...current.times, ...next.times];
              next.comment =
                current.comment !== undefined
                  ? current.comment
                  : '' || next.comment !== undefined
                  ? next.comment
                  : '';
              return [];
            }
            return current;
          });

          mapSections.push(item);
        }
      });
    }
    setLoadedTimes(true);
    setSections(mapSections);
    setFilteredTimeSets(filterTimeSetsByEventId(mapSections, numberId));
  }, [availableTimes, setLoadedTimes, unitId]);

  function filterTimeSetsByEventId(
    sections: Section[],
    eventId: number
  ): TimeSet[] {
    const filteredTimeSets: TimeSet[] = [];

    sections.forEach((section) => {
      section.timeSets.forEach((timeSet) => {
        if (timeSet.id === eventId) {
          filteredTimeSets.push(timeSet);
        }
      });
    });

    return filteredTimeSets;
  }

  return (
    <div>
      {loadedTimes === false && <Loader />}
      {loadedTimes === true && guests !== 'maxGuests' && eventId !== ''
        ? filteredTimeSets.map((timeSet) => {
            const recoupTime =
              timeSet.recoupTime !== undefined ? timeSet.recoupTime : 0;

            return (
              <div key={timeSet.id} style={{ marginTop: '20px' }}>
                <RestaurantCard lang={lang} nextStep onClick={() => {}} data={timeSet} />
                {timeSet.times.map((time) => {
                  if (isValidTime(time, guests)) {
                    return (
                      <ButtonTime
                        setButtonTime={setButtonTime}
                        setButtonTimeEnd={setButtonTimeEnd}
                        time={time}
                        setRuleId={setRuleId}
                        timeSetId={numberId}
                        setRecoupTime={setRecoupTime}
                        recoupTime={recoupTime}
                        key={`unit${eventId}-${moment(time.start).format(
                          'HH:mm'
                        )}`}
                      />
                    );
                  }
                })}
              </div>
            );
          })
        : sections.map((section) => {
            return (
              <div key={`unit${section.id}`}>
                {section.timeSets.length !== 0 &&
                  section.timeSets.map((timeSet) => {
                    if (timeSet.isTextRow === true) {
                      return (
                        <div key={timeSet.id} style={{ marginTop: '20px' }}>
                          {timeSet.times.map((time) => {
                            {
                              time.start && (
                                <div>
                                  <RestaurantCard
                                    lang={lang}
                                    nextStep
                                    onClick={() => {}}
                                    data={timeSet}
                                  />
                                </div>
                              );
                            }
                            <ButtonsTimesContainer
                              timeSet={timeSet}
                              guests={guests}
                              setButtonTime={setButtonTime}
                              setButtonTimeEnd={setButtonTimeEnd}
                              setRuleId={setRuleId}
                              setRecoupTime={setRecoupTime}
                            />;
                          })}
                        </div>
                      );
                      // eslint-disable-next-line no-else-return
                    } else {
                      for (let i = 0; i < timeSet.times.length; i++) {
                        const timeMap = timeSet.times[i];
                        if (
                          timeMap.availableSeats.includes(guests) &&
                          moment(timeMap.availableUntil).format(
                            'YYYY-MM-DDTHH:mm:ss'
                          ) >=
                            moment()
                              .tz('Europe/Stockholm')
                              .format('YYYY-MM-DDTHH:mm:ss')
                        ) {
                          return (
                            <div key={timeSet.id} style={{ marginTop: '20px' }}>
                              <RestaurantCard
                                lang={lang}
                                nextStep
                                onClick={() => {}}
                                data={timeSet}
                              />
                              <ButtonsTimesContainer
                                timeSet={timeSet}
                                guests={guests}
                                setButtonTime={setButtonTime}
                                setButtonTimeEnd={setButtonTimeEnd}
                                setRuleId={setRuleId}
                                setRecoupTime={setRecoupTime}
                              />
                            </div>
                          );
                        }
                      }
                    }
                  })}
              </div>
            );
          })}
    </div>
  );
}

const StyledPhoneNum = styled.a`
  color: #fff;
  margin: 0 !important;
`;
