import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { StateUpdater, useMemo } from 'preact/hooks';
import { Time } from '../types';
import useBookingStore from '../../store/bookingStore';

const useStyles = makeStyles((theme) => ({
  root: {
    '&': {
      margin: '6px',
    },
  },
}));

interface ButtonTimeProps {
  setButtonTime: StateUpdater<string>;
  setButtonTimeEnd: StateUpdater<string>;
  time: Time;
  setRuleId: StateUpdater<string>;
  timeSetId: number;
  recoupTime: number;
  setRecoupTime: StateUpdater<number>;
}

export default function ButtonTime({
  setButtonTime,
  setButtonTimeEnd,
  time,
  setRuleId,
  timeSetId,
  recoupTime,
  setRecoupTime,
}: ButtonTimeProps) {
  const classes = useStyles();
  const { activeButtonTime, setActiveButtonTime } = useBookingStore();

  const DisabledTimeZoneStart = useMemo(() => {
    const tmp = time.start.replace(/[+Z].*$/, '');

    return tmp;
  }, [time.start]);

  const DisabledTimeZoneEnd = useMemo(() => {
    const tmp = time.end.replace(/[+Z].*$/, '');

    return tmp;
  }, [time.end]);

  const formatedTime = `${timeSetId}-${DisabledTimeZoneStart}`;
  const isActiveButtonTime = activeButtonTime === formatedTime;

  return (
    <Button
      style={{
        width: "67px",
      }}
      variant={isActiveButtonTime ? 'contained' : 'outlined'}
      color={isActiveButtonTime ? 'primary' : 'default'}
      onClick={() => {
        setActiveButtonTime(formatedTime);
        setButtonTime(DisabledTimeZoneStart);
        setRuleId(timeSetId.toString());
        setButtonTimeEnd(DisabledTimeZoneEnd);
        setRecoupTime(recoupTime);
      }}
      className={`${classes.root} timeset-id-${timeSetId}`}
    >
      {moment(DisabledTimeZoneStart).format('HH:mm')}
    </Button>
  );
}
