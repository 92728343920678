import { h, JSX } from 'preact';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import constants from '../constants';

const stripePromise = loadStripe(constants.STRIPE_KEY!);

interface WithStripeProps {
  children: JSX.Element | JSX.Element[];
  locale?: any;
}

const WithStripe = ({ children, locale }: WithStripeProps) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{ locale: locale ? locale : 'auto' }}
    >
      {children}
    </Elements>
  );
};

export default WithStripe;
