import { create } from 'zustand'
import { localStorageGet, localStorageSet } from '../helpers/localstorage';
import { Time } from '../components/types/index';

interface BookingStore {
  activeButtonTime: string;
  setActiveButtonTime: (time: string) => void;
  activeStep: number;
  setActiveStep: (step: number) => void;
  eventId: string;
  setEventId: (id: string) => void;
  skipped: string[];
  setSkipped: (skiped: string[]) => void;
  goLastScreen: () => void;
  validTimes: Time[];
  setValidTimes: (times: Time[]) => void;
  guests: string;
  setGuests: (guest: string) => void;
  resetState: () => void
}

const useBookingStore = create<BookingStore>((set) => ({
  activeButtonTime: "",
  setActiveButtonTime: (time) => set({ activeButtonTime: time }),
  activeStep: 0,
  setActiveStep: (step) => {
    const lastHistory: number[] = JSON.parse(localStorageGet("history") || "[]")
    if (lastHistory[lastHistory.length - 1] !== step) {
      const newHistory = [...lastHistory, step]
      localStorageSet("history", JSON.stringify(newHistory))
    }
    set({ activeStep: step })
  },
  eventId: "",
  setEventId: (id) => set({ eventId: id }),
  skipped: [],
  setSkipped: (skiped) => set({ skipped: skiped }),

  goLastScreen: () => {
    const history: number[] = JSON.parse(localStorageGet("history") || "[]")
    if (history.length > 0) {
      const lastPage = history[history.length - 1]
      const prevLastPage = history[history.length - 2]

      if (lastPage > prevLastPage) {
        history.pop();
        localStorageSet("history", JSON.stringify(history))
        set({ activeStep: history[history.length - 1] })
      } else {
        set({ activeStep: lastPage - 1 })
      }
    }
  },
  validTimes: [],
  setValidTimes: (times) => set({ validTimes: times }),
  guests: "",
  setGuests: (guest) => {
    set({ guests: guest })
  },
  resetState: () => {
    localStorageSet("history", "[]")
    set({
      activeButtonTime: "",
      activeStep: 0,
      eventId: "",
      // skipped: [],
      validTimes: [],
      guests: "",
    })
  }
}))

export default useBookingStore;
