import { FunctionComponent } from 'preact';
import { AvalibleTimes, Section, Time } from '../types';
import { StateUpdater, useEffect, useState } from 'preact/hooks';
import moment from 'moment';
import {
  ListItemIcon,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Timelapse } from '@material-ui/icons';
import useBookingStore from '../../store/bookingStore';
import { isValidTime } from '../../helpers/isTimeGreaterThanNow';
import { Steps } from '../types/bookingSteps';

const { activeStep } = useBookingStore.getState();

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& .MuiTextField-root': {
      width: activeStep === Steps.EventSelector ? '98px' : '',
    },
    '&': {
      '& .MuiListItemIcon-root': {
        color: theme.palette.common.black,
        position: 'absolute',
        top: '15px',
        left: '5px',
      },
      '& .MuiSelect-root': {
        paddingLeft: '15px',
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

interface TimeSeelectorProps {
  dateBooking: string;
  guests: string;
  availableTimes: AvalibleTimes[];
  setButtonTime: StateUpdater<string>;
  setButtonTimeEnd: StateUpdater<string>;
  unitId: string;
  setRuleId: StateUpdater<string>;
}

const TimeSelector: FunctionComponent<TimeSeelectorProps> = ({
  dateBooking,
  guests,
  availableTimes,
  setButtonTime,
  setButtonTimeEnd,
  unitId,
  setRuleId,
}) => {
  const classes = useStyles();
  const {
    setActiveButtonTime,
    activeButtonTime,
    validTimes,
    setValidTimes,
    setEventId,
  } = useBookingStore();

  const [activeButtonTimesInternal, setActiveButtonTimesInternal] =
    useState<string>('');
  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => {
    const mapSections: Section[] = [];

    if (availableTimes[0].id == unitId) {
      const sections = availableTimes[0].sections;
      sections.map((item) => {
        if (item.timeSets.length !== 0) {
          item.timeSets.map((timeSet) => {
            timeSet.times.map((time) => {
              time.id = timeSet.id;
            });
          });

          item.timeSets = item.timeSets.flatMap((current, i, array) => {
            const next = array[i + 1];
            if (next && current.title === next.title) {
              next.times = [...current.times, ...next.times];
              next.comment =
                current.comment !== undefined
                  ? current.comment
                  : '' || next.comment !== undefined
                  ? next.comment
                  : '';
              return [];
            }
            return current;
          });

          mapSections.push(item);
        }
      });
    }

    setSections(mapSections);

    const validTime: Time[] = [];

    for (const timeSets of mapSections) {
      for (const timeSet of timeSets.timeSets) {
        for (const time of timeSet.times) {
          const isAvailable = isValidTime(time, guests);
          if (isAvailable) {
            validTime.push(time);
          }
        }
      }
    }
    setValidTimes(validTime);
    if (activeButtonTime === '') {
      setActiveButtonTimesInternal('any');
    } else {
      setActiveButtonTimesInternal(activeButtonTime);
    }
  }, [availableTimes, unitId, dateBooking, guests]);

  const getTimeValue = (time: any) => `${time.id}-${time.start}`;

  const getTimeObject = (timeValue: any) => {
    const [id, ...start] = timeValue.split('-');
    return validTimes.find(
      (time) => time.id == id && time.start === start.join('-')
    );
  };

  const handleTimeChange = (event: any) => {
    setEventId('');
    const selectedTimeValue =
      event.target.value !== '' ? event.target.value : '';

    if (selectedTimeValue === 'any') {
      setActiveButtonTimesInternal('any');
      setActiveButtonTime('');
      setButtonTime('');
      setButtonTimeEnd('');
      setRuleId('');
      return;
    }

    const selectedTime = getTimeObject(selectedTimeValue);
    setActiveButtonTimesInternal(`${selectedTime?.id}-${selectedTime?.start}`);

    if (selectedTime) {
      const timeEnd = selectedTime.end.replace(/[+Z].*$/, '');
      const timeStart = selectedTime.start.replace(/[+Z].*$/, '');
      const activeTime = `${selectedTime.id}-${timeStart}`;
      setActiveButtonTime(activeTime);
      setButtonTime(timeStart);
      setButtonTimeEnd(timeEnd);
      setRuleId(selectedTime.id.toString());

      const updated = `${selectedTime.id}-${selectedTime.start}`;
      setActiveButtonTimesInternal(updated);
    }
  };

  return (
    <div className={classes.root}>
      <ListItemIcon>
        <Timelapse fontSize='medium' color='primary' />
      </ListItemIcon>
      <TextField
        select
        label='Time'
        id='time'
        value={activeButtonTimesInternal}
        variant='outlined'
        onChange={handleTimeChange}
        required
        className={classes.root}
        disabled={validTimes.length === 0}
        InputLabelProps={{
          shrink: true,
        }}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                maxHeight: 500,
              },
            },
          },
        }}
      >
        <MenuItem key={`unit-any`} value={'any'} selected>
          Any
        </MenuItem>
        {validTimes.map((time) => {
          const timeValue = getTimeValue(time);
          return (
            <MenuItem key={`unit${time.id}-${time.start}`} value={timeValue}>
              {time.start && moment(time.start).format('HH:mm')}
            </MenuItem>
          );
        })}
      </TextField>
    </div>
  );
};

export default TimeSelector;
