import { CircularProgress } from '@material-ui/core';

interface LoaderProps {
  size?: 'normal' | 'small';
}

export default function Loader({ size = 'normal' }: LoaderProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9999,
        transition: 'all 0.3s ease-in-out',
        cursor: 'pointer',
        userSelect: 'none',
      }}
    >
      <div
        className={
          size === 'normal' ? 'box-loader' : 'box-loader box-loader-small'
        }
      >
        <CircularProgress
          style={{
            color: '#ADDCCF',
          }}
        />
      </div>
    </div>
  );
}
