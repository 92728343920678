import React from 'react';
import { Lang, TimeSet } from '../types';
import { FunctionalComponent } from 'preact';
import useBookingStore from '../../store/bookingStore';
import { Steps } from '../types/bookingSteps';

interface RestaurantCardProps {
  data: TimeSet;
  nextStep?: boolean;
  onClick: any;
  lang: Lang;
}

const RestaurantCard: FunctionalComponent<RestaurantCardProps> = ({
  data,
  onClick,
  nextStep = false,
  lang,
}) => {
  const { setEventId, eventId, activeStep } = useBookingStore();
  const handleClick = () => {
    if (nextStep) {
      setEventId(data.id.toString());
      onClick();
    }
  };

  const isSelected =
    parseInt(eventId, 10) === data.id && activeStep === Steps.EventSelector;

  return (
    <div
      key={data.id}
      className='box-restaurant'
      onClick={handleClick}
      style={{ cursor: nextStep ? 'pointer' : 'default' }}
    >
      <img src='/assets/vassa.jpg' alt='img-restaurant' className='box-img' />
      <div
        className='box-info'
        style={{
          backgroundColor: isSelected ? '#ADDCCF' : '',
        }}
      >
        <h3 style={{ color: isSelected ? '#000' : '' }}>{data.title}</h3>
        <p style={{ color: isSelected ? 'rgba(0, 0, 0, 0.5)' : '' }}>
          {data.comment ? data.comment : lang.descriptionEvent}
        </p>
      </div>
    </div>
  );
};

export default RestaurantCard;
