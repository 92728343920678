import { StateUpdater, useEffect, useMemo, useState } from 'preact/hooks';
import ButtonConfirm from './Button/ButtonConfirm';
import NativeSelects from './NativeSelects';
import Loader from './Loader';
import {
  Lang,
  AvalibleTimes,
  CaspecoHomeSettings,
  DayState,
  Section,
  TimeSet,
} from './types/index';
import homeAPI from '../services/homeAPI';
import RestaurantCard from './Card/RestaurantCard';
import NoAvailableTimes from './Card/NoAvailableTimes';
import useBookingStore from '../store/bookingStore';
import { isValidTime } from '../helpers/isTimeGreaterThanNow';
import { Steps } from './types/bookingSteps';

interface Step1Props {
  lang: Lang;
  buttonTime: string;
  dateBooking: string;
  setDateBooking: StateUpdater<string>;
  fromDate: string;
  toDate: string;
  unitId: string;
  system: string;
  setBookPhone: StateUpdater<string>;
  setBookEmail: StateUpdater<string>;
  locale: string;
  availableTimes: AvalibleTimes[];
  setAvailableTimes: StateUpdater<AvalibleTimes[]>;
  setFromDate: StateUpdater<string>;
  setToDate: StateUpdater<string>;
  setDisplayMessageBox: StateUpdater<boolean>;
  paramPromo: string;
  caspecoSettings: CaspecoHomeSettings | undefined;
  dayStates: DayState[];
  setDayStates: StateUpdater<DayState[]>;
  setButtonTime: StateUpdater<string>;
  setButtonTimeEnd: StateUpdater<string>;
  setRuleId: StateUpdater<string>;
}

export default function StepTime2({
  lang,
  dateBooking,
  setDateBooking,
  fromDate,
  toDate,
  unitId,
  system,
  setBookPhone,
  setBookEmail,
  locale,
  availableTimes,
  setAvailableTimes,
  setFromDate,
  setToDate,
  setDisplayMessageBox,
  paramPromo,
  caspecoSettings,
  dayStates,
  setDayStates,
  setButtonTime,
  setButtonTimeEnd,
  setRuleId,
}: Step1Props) {
  const {
    activeButtonTime,
    setActiveButtonTime,
    activeStep,
    setActiveStep,
    eventId,
    guests,
    setGuests,
  } = useBookingStore();
  const dateBookingParse = `${dateBooking.substring(0, 19)}.000Z`;
  const dateBookingISO = useMemo(() => dateBookingParse, [dateBookingParse]);
  const [isLoadedAvailableTimes, setIsLoadedAvailableTimes] = useState(false);
  const [showRestaurantCard, setRestaurantShowCard] = useState<boolean>(true);
  const [maxGuests, setMaxGuests] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const showCard: Section[] = availableTimes[0].sections;

  useEffect(() => {
    if (!firstLoad) {
      homeAPI
        .getDayStates(fromDate, toDate, unitId, paramPromo, system)
        .then((data) => {
          if (data) setDayStates(data);
        });
    }
  }, [fromDate, toDate, unitId]);

  useEffect(() => {
    if (!firstLoad) {
      setRestaurantShowCard(false);
      homeAPI
        .getAvailableTimes(dateBookingISO, unitId, paramPromo, system)
        .then((data: any) => {
          setAvailableTimes(data);
          setIsLoadedAvailableTimes(true);
          setRestaurantShowCard(true);
        });
    } else {
      setFirstLoad(false);
      setIsLoadedAvailableTimes(true);
    }
  }, [dateBookingISO, unitId, setAvailableTimes]);

  const updateSettingsStates = (data: CaspecoHomeSettings) => {
    setBookPhone(data.phoneNumber);
    setBookEmail(data.email);
    setDisplayMessageBox(data.displayMessageBox);
    setGuests(guests === '' ? data.defaultAmountGuests : guests);
    setMaxGuests(data.unitsMetaData.maxWebGuests);
  };

  useEffect(() => {
    if (firstLoad && caspecoSettings) {
      updateSettingsStates(caspecoSettings);
    } else {
      homeAPI.getCaspecoSettings(unitId, system).then((data) => {
        if (data) updateSettingsStates(data);
      });
    }
  }, [unitId]);

  const getFilteredTimesets = (sections: Section[]) => {
    const timeSets: TimeSet[] = [];
    const [_, ...start] = activeButtonTime.split('-');
    const criteria = start.join('-');

    sections.forEach((section) => {
      section.timeSets.forEach((ts) => {
        if (ts.times.length !== 0) {
          const exists = ts.times.some((tm) => {
            if (activeButtonTime === '' && isValidTime(tm, guests)) {
              return tm.availableSeats.includes(guests);
            }
            return tm.start === criteria && isValidTime(tm, guests);
          });

          const shouldAddName = !timeSets.some(
            (ts) => ts.name === section.name
          );

          if (exists) {
            timeSets.push({
              ...ts,
              name: shouldAddName ? section.name : undefined,
            });
          }
        }
      });
    });
    return timeSets;
  };

  const timesetsToRender = getFilteredTimesets(showCard);

  return (
    <div style={{ padding: '0 10px' }} className='steps-time-container'>
      {/* {isLoadedAvailableTimes === false && <Loader />} */}
      {isLoadedAvailableTimes && (
        <>
          <NativeSelects
            dateBooking={dateBooking}
            setDateBooking={setDateBooking}
            dayStates={dayStates}
            availableTimes={availableTimes}
            lang={lang}
            locale={locale}
            maxGuests={maxGuests}
            setFromDate={setFromDate}
            setToDate={setToDate}
            unitId={unitId}
            setButtonTime={setButtonTime}
            setButtonTimeEnd={setButtonTimeEnd}
            setRuleId={setRuleId}
          />
          {showRestaurantCard === false && <Loader />}
          {showRestaurantCard === false && <div style={{height: 300}} />}
          {showRestaurantCard && timesetsToRender.length > 0 ? (
            timesetsToRender.map((timeSet) => {
              return (
                <div key={timeSet.name}>
                  {timeSet.name && (
                    <h2 className='restaurant-title'>{timeSet.name}</h2>
                  )}
                  <div key={timeSet.id}>
                    <RestaurantCard
                      lang={lang}
                      nextStep
                      onClick={() => {
                        if (activeButtonTime === '') {
                          setActiveStep(Steps.EventTimes);
                        }
                      }}
                      data={timeSet}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                padding: '60px 0px 30px ',
              }}
            >
              {showRestaurantCard && <NoAvailableTimes />}
            </div>
          )}
        </>
      )}

      <ButtonConfirm
        activeStep={activeStep}
        lang={lang}
        eventId={eventId}
        handleNext={() =>
          setActiveStep(
            activeButtonTime !== '' ? Steps.UserForm : Steps.EventTimes
          )
        }
        handleBack={() => {
          setActiveStep(Steps.DateTimeSelector);
          setActiveButtonTime('');
        }}
      />
    </div>
  );
}
