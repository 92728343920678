import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Loader from '../Loader';
import { Lang } from '../types';
import { StripeError } from '@stripe/stripe-js';
import { Steps } from '../types/bookingSteps';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .MuiButton-root': {
      fontSize: '20px',
    },
  },
  verticalRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '1rem',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .MuiButton-root': {},
  },
}));

interface ButtonConfirmProps {
  activeStep: number;
  lang: Lang;
  activeButtonTime?: string;
  eventId?: string;
  handleNext: (event: any) => void;
  handleBack: () => void;
  errorSendData?: boolean;
  sendingData?: boolean;
  hasVerticalButtons?: boolean;
  hasCardError?: boolean;
  hasAddedCardError?: StripeError;
  acceptTerms?: boolean;
}

export default function ButtonConfirm({
  activeStep,
  lang,
  activeButtonTime,
  handleNext,
  handleBack,
  errorSendData,
  sendingData,
  hasVerticalButtons,
  hasCardError,
  hasAddedCardError,
  acceptTerms,
  eventId,
}: ButtonConfirmProps) {
  const classes = useStyles();

  return (
    <>
      {hasAddedCardError && (
        <div>
          <div className='error-text'>{hasAddedCardError?.message}</div>
        </div>
      )}
      {errorSendData && (
        <div>
          <div className='error-text'>{lang.postError}</div>
          <div className='error-text'>{lang.postErrorDetails}</div>
        </div>
      )}
      <div className={hasVerticalButtons ? classes.verticalRoot : classes.root}>
        {activeStep >= Steps.DateTimeSelector && (
          <Button
            variant='outlined'
            color='primary'
            size='small'
            onClick={() => handleBack()}
            disabled={sendingData}
          >
            {lang.prev}
          </Button>
        )}
        <Button
          variant='contained'
          color='primary'
          type='submit'
          size={hasVerticalButtons ? 'medium' : 'large'}
          onClick={handleNext}
          disabled={
            hasCardError ||
            (activeStep === Steps.EventSelector && eventId === '') ||
            (activeStep === Steps.EventTimes && activeButtonTime === '') ||
            sendingData ||
            (activeStep === Steps.Payment && errorSendData === true) ||
            (activeStep === Steps.BookingSummary && !acceptTerms)
          }
        >
          {hasVerticalButtons ? lang.registerCard : lang.next}
        </Button>
      </div>

      {sendingData && <Loader />}
    </>
  );
}
