import { lazy, StateUpdater, Suspense } from 'preact/compat';
import StepFooter from './StepFooter';
import { Lang, BookingData } from './types/index';
import useBookingStore from '../store/bookingStore';

const Step3 = lazy(() => import('./Step3'));
const Step4 = lazy(() => import('./Step4'));
const StepCardWithStripe = lazy(() => import('./StepCardWithStripe'));

interface ContainerAddCardProps {
  skipped: string[];
  lang: Lang;
  bookingData: BookingData;
  queryParams: string;
  paramTheme: string;
}

export default function ContainerAddCard({
  skipped,
  lang,
  bookingData,
  queryParams,
  paramTheme,
}: ContainerAddCardProps) {
  const system = undefined;
  const unitId = undefined;
  const { activeStep } = useBookingStore();

  return (
    <>
      <div className='container'>
        <Suspense fallback={<div />}>
          {bookingData?.card_added === false && (
            <>
              {activeStep === 0 && (
                <Step3
                  lang={lang}
                  buttonTime={bookingData?.time}
                  skipped={skipped}
                  firstName={bookingData?.first_name}
                  lastName={bookingData?.last_name}
                  phone={bookingData?.phone}
                  email={bookingData?.email}
                  guests={bookingData?.guests}
                  dateBooking={bookingData?.time}
                  buttonTimeEnd={bookingData?.time_end}
                  selectedParamNoShow={{
                    amount_per_person: bookingData?.amount_per_person,
                  }}
                />
              )}
              {activeStep === 1 && (
                <StepCardWithStripe
                  lang={lang}
                  typeFlow='AddCard'
                  queryParams={queryParams}
                  paramTheme={paramTheme}
                />
              )}
            </>
          )}
          {activeStep === 2 && (
            <Step4
              system={system}
              unitId={unitId}
              lang={lang}
              buttonTime={bookingData?.time}
              skipped={skipped}
              firstName={bookingData?.first_name}
              lastName={bookingData?.last_name}
              phone={bookingData?.phone}
              email={bookingData?.email}
              guests={bookingData?.guests}
              dateBooking={bookingData?.time}
              buttonTimeEnd={bookingData?.time_end}
              bookEmail={bookingData?.email}
              bookPhone={bookingData?.phone}
              globalBookingNumber={bookingData?.global_booking_number}
              selectedParamNoShow={{
                amount_per_person: bookingData?.amount_per_person,
              }}
            />
          )}
        </Suspense>
      </div>
      <StepFooter />
    </>
  );
}
