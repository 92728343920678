import { StateUpdater, useEffect, useMemo, useState } from 'preact/hooks';
import ButtonConfirm from './Button/ButtonConfirm';
import ButtonsSelect from './Button/ButtonsSelect';
import NativeSelects from './NativeSelects';
import Loader from './Loader';
import {
  Lang,
  AvalibleTimes,
  CaspecoHomeSettings,
  DayState,
} from './types/index';
import { convertToLinkedText } from '../helpers/convertToLinkedText';
import homeAPI from '../services/homeAPI';
import useBookingStore from '../store/bookingStore';
import { Steps } from './types/bookingSteps';
import { mapSectionsFunction, validTimes } from '../helpers/validTimes';

interface Step1Props {
  lang: Lang;
  buttonTime: string;
  setButtonTime: StateUpdater<string>;
  dateBooking: string;
  setDateBooking: StateUpdater<string>;
  fromDate: string;
  toDate: string;
  unitId: string;
  system: string;
  setButtonTimeEnd: StateUpdater<string>;
  setBookPhone: StateUpdater<string>;
  setBookEmail: StateUpdater<string>;
  locale: string;
  availableTimes: AvalibleTimes[];
  setAvailableTimes: StateUpdater<AvalibleTimes[]>;
  setRuleId: StateUpdater<string>;
  setFromDate: StateUpdater<string>;
  setToDate: StateUpdater<string>;
  setRecoupTime: StateUpdater<number>;
  setDisplayMessageBox: StateUpdater<boolean>;
  paramPromo: string;
  caspecoSettings: CaspecoHomeSettings | undefined;
  dayStates: DayState[];
  setDayStates: StateUpdater<DayState[]>;
}

export default function StepTime3({
  lang,
  setButtonTime,
  dateBooking,
  setDateBooking,
  fromDate,
  toDate,
  unitId,
  system,
  setButtonTimeEnd,
  setBookPhone,
  setBookEmail,
  locale,
  availableTimes,
  setAvailableTimes,
  setRuleId,
  setFromDate,
  setToDate,
  setRecoupTime,
  setDisplayMessageBox,
  paramPromo,
  caspecoSettings,
  dayStates,
  setDayStates,
}: Step1Props) {
  const {
    activeButtonTime,
    setActiveButtonTime,
    setActiveStep,
    activeStep,
    setEventId,
    guests,
    setGuests,
  } = useBookingStore();
  const dateBookingParse = `${dateBooking.substring(0, 19)}.000Z`;
  const dateBookingISO = useMemo(() => dateBookingParse, [dateBookingParse]);
  const [isLoadedAvailableTimes, setIsLoadedAvailableTimes] = useState(false);
  const [loadedTimes, setLoadedTimes] = useState(false);
  const [messageExceededGuests, setMessageExceededGuests] = useState('');
  const [maxGuests, setMaxGuests] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (!firstLoad) {
      homeAPI
        .getDayStates(fromDate, toDate, unitId, paramPromo, system)
        .then((data) => {
          if (data) setDayStates(data);
        });
    }
  }, [fromDate, toDate, unitId]);

  useEffect(() => {
    if (!firstLoad) {
      homeAPI
        .getAvailableTimes(dateBookingISO, unitId, paramPromo, system)
        .then((data: any) => {
          setAvailableTimes(data);
          setIsLoadedAvailableTimes(true);
        });
    } else {
      setFirstLoad(false);
      setIsLoadedAvailableTimes(true);
    }
  }, [dateBookingISO, unitId, setAvailableTimes]);

  const updateSettingsStates = (data: CaspecoHomeSettings) => {
    setBookPhone(data.phoneNumber);
    setBookEmail(data.email);
    setMessageExceededGuests(data.unitsMetaData.messageExceededGuests);
    setDisplayMessageBox(data.displayMessageBox);
    setGuests(guests === '' ? data.defaultAmountGuests : guests);
    setMaxGuests(data.unitsMetaData.maxWebGuests);
  };

  useEffect(() => {
    if (firstLoad && caspecoSettings) {
      updateSettingsStates(caspecoSettings);
    } else {
      homeAPI.getCaspecoSettings(unitId, system).then((data) => {
        if (data) updateSettingsStates(data);
      });
    }
  }, [unitId]);

  const handleGoBack = () => {
    setLoadedTimes(false);
    setActiveButtonTime('');
    setEventId('');
    setActiveStep(Steps.EventSelector);
  };

  const handleNext = () => {
    setActiveStep(Steps.UserForm);
  };

  const validTimesArray = useMemo(() => validTimes(
    mapSectionsFunction(availableTimes, unitId), guests),
  [availableTimes, unitId, guests]);

  return (
    <div style={{ padding: '0 10px' }} className='steps-time-container'>
      {isLoadedAvailableTimes === false && <Loader />}
      {isLoadedAvailableTimes && (
        <>
          <NativeSelects
            dateBooking={dateBooking}
            setDateBooking={setDateBooking}
            dayStates={dayStates}
            availableTimes={availableTimes}
            lang={lang}
            locale={locale}
            maxGuests={maxGuests}
            setFromDate={setFromDate}
            setToDate={setToDate}
            unitId={unitId}
            setButtonTime={setButtonTime}
            setButtonTimeEnd={setButtonTimeEnd}
            setRuleId={setRuleId}
          />
          <ButtonsSelect
            setButtonTime={setButtonTime}
            availableTimes={availableTimes}
            setButtonTimeEnd={setButtonTimeEnd}
            unitId={unitId}
            guests={guests}
            loadedTimes={loadedTimes}
            setLoadedTimes={setLoadedTimes}
            setRuleId={setRuleId}
            setRecoupTime={setRecoupTime}
            lang={lang}
          />
          {
            loadedTimes &&  validTimesArray.length === 0 &&
            <p className="step-text">
              {caspecoSettings?.messageBookingClosed}
            </p>
          }

          { (loadedTimes && paramPromo !== 'amex_dining' && validTimesArray.length > 0)?
            <p className="step-text">
              {convertToLinkedText(messageExceededGuests)}
            </p>
            :
            null
          }
        </>
      )}
      <ButtonConfirm
        activeStep={activeStep}
        lang={lang}
        activeButtonTime={activeButtonTime}
        handleNext={handleNext}
        handleBack={handleGoBack}
      />
    </div>
  );
}
