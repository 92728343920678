import { isValidTime } from "./isTimeGreaterThanNow";
import { AvalibleTimes, Section } from '../components/types/index';

export const validTimes = (sections: Section[], guests: string) => {
  const validTimesArray: any[] = [];
  sections.forEach((section) => {
    section.timeSets.forEach((timeSet) => {
      timeSet.times.forEach((time) => {
        if (isValidTime(time, guests)) {
          validTimesArray.push(time);
        }
      });
    });
  });
  return validTimesArray;
}


export const mapSectionsFunction = (availableTimes: AvalibleTimes[], unitId: string) => {
  const mapSections:Section[] = [];

  if (availableTimes[0].id == unitId) {
    const sections = availableTimes[0].sections;
    sections.map((item) => {
      if (item.timeSets.length !== 0) {
        item.timeSets.map((timeSet) => {
          timeSet.times.map((time) => {
            time.id = timeSet.id;
          });
        });

        item.timeSets = item.timeSets.flatMap((current, i, array) => {
          const next = array[i + 1];
          if (next && current.title === next.title) {
            next.times = [...current.times, ...next.times];
            next.comment =
              current.comment !== undefined
                ? current.comment
                : "" || next.comment !== undefined
                ? next.comment
                : "";
            return [];
          }
          return current;
        });

        mapSections.push(item);
      }
    });
  }
  return mapSections;
};
