import { Params } from '../../components/types/index';
import oldHome from './oldIndex';
import newHome from './newIndex';

let queryParams: URLSearchParams;
let params: Params;

try {
  queryParams = new URLSearchParams(window.location.search) ?? {};
} catch (e) {
  queryParams = {} as URLSearchParams;
}

try {
  params = JSON.parse(window.name) ?? {};
} catch (e) {
  params = {} as Params;
}

const validModes = ['center', 'right'];
let mode = queryParams.get('mode') ?? 'right';
mode = validModes.includes(mode) ? mode : 'right';

export default mode === 'right' ? newHome : oldHome;
