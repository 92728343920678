import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { StateUpdater, useEffect, useMemo, useState } from 'preact/hooks';
import CustomPhoneNumber from './PhoneNumber';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ButtonConfirm from './Button/ButtonConfirm';
import { Lang, Errors, CaspecoHomeSettings } from './types/index';
import useBookingStore from '../store/bookingStore';
import { Steps } from './types/bookingSteps';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: '8px 0',
    },
    '& .form-row.flex': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
    },
    '& .form-row.flex > *': {
      width: '50%',
    },
    '& .form-row.flex > *:last-child': {
      marginLeft: '1rem',
    },
    '& .form-row': {
      marginTop: '1.5rem',
    },
  },
}));

const regEmail =
  /^(([^<>()\[\]\\.,;:\s/@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

interface Step2Props {
  lang: Lang;
  firstName: string;
  setFirstName: StateUpdater<string>;
  lastName: string;
  setLastName: StateUpdater<string>;
  phone: string;
  setPhone: StateUpdater<string>;
  email: string;
  setEmail: StateUpdater<string>;
  message: string;
  setMessage: StateUpdater<string>;
  displayMessageBox?: boolean;
  hideMessageForm: boolean;
  caspecoSettings: CaspecoHomeSettings | undefined
}

export default function Step2({
  lang,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phone,
  setPhone,
  email,
  setEmail,
  message,
  setMessage,
  displayMessageBox,
  hideMessageForm,
  caspecoSettings
}: Step2Props) {
  const { setActiveStep, activeStep, goLastScreen } = useBookingStore();
  const classes = useStyles();
  const [errors, setErrors] = useState<Errors>({} as Errors);

  useEffect(() => {
    regEmail.test(String(email).toLowerCase()); // Necesary to fix error in email validation in user form
  }, []);

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    setErrors({} as Errors);

    const errors: Errors = {
      firstName: firstName === '',
      lastName: lastName === '',
      phone: phone === '',
      email: email === '' || !regEmail.test(String(email).toLowerCase()),
    };

    setErrors(errors);

    if (Object.values(errors).some(Boolean)) {
      return;
    }

    setActiveStep(Steps.BookingSummary);
  };

  const handleInputChange = (setState: StateUpdater<any>) => (event: any) =>
    setState(event.target.value);

  const handlePhoneChange = (value: string | null) => {
    if (value && isValidPhoneNumber(value)) {
      setPhone(value);
    }
  };

  const isShowMessage = useMemo(() => {
    if (displayMessageBox && hideMessageForm === false) {
      return true;
    }
    return false;
  }, [displayMessageBox, hideMessageForm]);

  return (
    <div className={classes.root}>
      <h2 className='step-title'>{lang.stepsEnum['200']}</h2>
      <form noValidate autoComplete='off' onSubmit={handleFormSubmit}>
        <div className='form-row flex'>
          <TextField
            id='firstName'
            label={lang.contact.fname}
            variant='outlined'
            value={firstName}
            onChange={handleInputChange(setFirstName)}
            helperText={errors.firstName && lang.contact.validation.fname}
            error={errors.firstName}
          />
          <TextField
            id='lastName'
            label={lang.contact.lname}
            variant='outlined'
            value={lastName}
            onChange={handleInputChange(setLastName)}
            helperText={errors.lastName && lang.contact.validation.lname}
            error={errors.lastName}
          />
        </div>
        <div className='form-row'>
          <PhoneInput
            defaultCountry={'SE'}
            inputComponent={CustomPhoneNumber}
            fullWidth
            variant='outlined'
            label={lang.contact.mobile}
            value={phone}
            onChange={handlePhoneChange}
            name='phone'
            helperText={errors.phone && lang.contact.validation.mobile}
            error={errors.phone}
          />
        </div>
        <div className='form-row'>
          <TextField
            id='email'
            type='email'
            label={lang.contact.email}
            variant='outlined'
            fullWidth
            mt={20}
            value={email}
            onChange={handleInputChange(setEmail)}
            helperText={errors.email && lang.contact.validation.email}
            error={errors.email}
          />
        </div>
        {isShowMessage && (
          <>
            <div className='form-row'>
            <p className="step-text">
                {caspecoSettings?.messageDescription}
              </p>
              <TextField
                id='message'
                label={lang.contact.message}
                variant='outlined'
                inputProps={{
                  maxLength: 255,
                }}
                multiline
                fullWidth
                mt={20}
                rows={4}
                value={message}
                onChange={handleInputChange(setMessage)}
              />
              <div style='margin-top: 5px'>
                <span
                  className='text-base'
                  style={message.length >= 255 ? 'color: red' : undefined}
                >
                  {message.length}
                </span>
                <span className='text-base'>/255</span>
              </div>
            </div>
          </>
        )}
        <br />
        <ButtonConfirm
          activeStep={activeStep}
          lang={lang}
          handleNext={handleFormSubmit}
          handleBack={() => goLastScreen()}
        />
      </form>
    </div>
  );
}
