import { FunctionComponent } from "preact";

interface MaitresSimbolProps {
  width?: string;
  height?: string;
}

const MaitresSimbol: FunctionComponent<MaitresSimbolProps> = ({
  width = 22,
  height = 22,
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}`}
      height={`${height}`}
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.9636 11.2623L14.2384 15.1237H21.0827L14.6045 7.33203H7.39703L0.916016 15.1237H7.76027L10.9636 11.2623Z'
        fill='white'
      />
    </svg>
  );
};

export default MaitresSimbol;