import { h } from 'preact';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import constants from '../constants';
import StepCard, { StepCardProps } from './StepCard';

const stripePromise = loadStripe(constants.STRIPE_KEY!);

interface StepCardWithStripeProps extends StepCardProps {
  locale?: any;
}

const StepCardWithStripe = (props: StepCardWithStripeProps) => {
  const { locale } = props;

  return (
    <Elements
      stripe={stripePromise}
      options={{ locale: locale ? locale : 'auto' }}
    >
      <StepCard {...props} />
    </Elements>
  );
};

export default StepCardWithStripe;
