import enMessages from '../assets/lang/enMessages.webBooking.json';
import svMessages from '../assets/lang/svMessages.webBooking.json';
import frMessages from '../assets/lang/frMessages.webBooking.json';

export const getMessages = (langCode: string) => {
  switch (langCode) {
    case 'en':
      return enMessages;
    case 'sv':
      return svMessages;
    case 'fr':
      return frMessages;
    default:
      return enMessages;
  }
};
