import { useEffect, useState } from 'preact/hooks';
import useTheme from '../../hooks/useTheme';
import { selectContainerTheme } from '../../helpers/selectContainerTheme';
import { ThemeProvider } from '@material-ui/styles';
import Close from '@material-ui/icons/Close';
import closeApp from '../../helpers/closeApp';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepFooter from '../../components/StepFooter';
import { Lang } from '../../components/types/Translations';
import GiftCardOptionsStep from '../../components/GiftCards/GiftCardOptionsStep';
import { getMessages } from '../../helpers/getLanguageData';
import { GiftCardAmountOption, Template } from '../../components/types/index';
import GiftCardPaymentStep from '../../components/GiftCards/GiftCardPaymentStep';
import GiftCardSuccessStep from '../../components/GiftCards/GiftCardSuccessStep';
import giftCardAPI from '../../services/giftCardAPI';
import GiftCardDeliveryStep from '../../components/GiftCards/GiftCardDeliveryStep';
import { isValidLanguage } from '../../helpers/isValidLanguage';

let queryParams: URLSearchParams;

try {
  queryParams = new URLSearchParams(window.location.search) ?? {};
} catch (e) {
  queryParams = {} as URLSearchParams;
}

const restaurantSlug = queryParams.get('r');
const hash = queryParams.get('hash');
const paramRestaurant = restaurantSlug || hash || '';
const urlParamTheme = queryParams.get('theme') ?? 'dark';
const urlParamLang = isValidLanguage(queryParams.get('lang'));

const defalutAmountOptions: GiftCardAmountOption[] = [
  {
    value: 10000,
    label: '100 SEK',
  },
  {
    value: 25000,
    label: '250 SEK',
  },
  {
    value: 50000,
    label: '500 SEK',
  },
  {
    value: 100000,
    label: '1000 SEK',
  },
];

interface GiftCardProps {
  path: string;
}

let steps = [];
let stepsComponents = [];

export default function GiftCards({}: GiftCardProps) {
  const [paramTheme, setParamTheme] = useState(urlParamTheme);
  const theme = useTheme(paramTheme);
  const [lang, setLang] = useState<Lang>(getMessages(urlParamLang || 'sv'));
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [amount, setAmount] = useState<number>(defalutAmountOptions[0].value);
  const [giftcardSlug, setGiftcardSlug] = useState<string | null>(null);
  const [presset, setPresset] = useState<Template>();
  const [cardType, setCardType] = useState('digital');
  const [error, setError] = useState<boolean>(false);
  const [deliveryInfo, setDeliveryInfo] = useState({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    postCode: '',
  });

  useEffect(() => {
    giftCardAPI
      .getSystemConfiguration(paramRestaurant, hash !== null)
      .then((data) => {
        if (data) {
          setParamTheme(data.theme || 'dark');
          setPresset(data.templates[0]);
        } else {
          setError(true);
        }
      });
  }, []);

  const getGiftCardOptionsStep = () => {
    return (
      <GiftCardOptionsStep
        email={email}
        setEmail={setEmail}
        quantity={quantity}
        setQuantity={setQuantity}
        amount={amount}
        setAmount={setAmount}
        options={defalutAmountOptions}
        setActiveStep={setActiveStep}
        lang={lang}
        paramRestaurant={paramRestaurant}
        error={error}
        cardType={cardType}
        setCardType={setCardType}
        presset={presset}
      />
    );
  };

  const getGiftCardDeliveryStep = () => {
    return (
      <GiftCardDeliveryStep
        amount={amount}
        quantity={quantity}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        lang={lang}
        deliveryInfo={deliveryInfo}
        setDeliveryInfo={setDeliveryInfo}
      />
    );
  };

  const getGiftCardPaymentStep = () => {
    return (
      <GiftCardPaymentStep
        amount={amount}
        quantity={quantity}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        lang={lang}
        paramTheme={paramTheme}
        email={email}
        setGiftcardSlug={setGiftcardSlug}
        presset={presset}
        deliveryInfo={deliveryInfo}
        cardType={cardType}
      />
    );
  };

  const getGiftCardSuccessStep = () => {
    return (
      <GiftCardSuccessStep
        email={email}
        giftcardSlug={giftcardSlug}
        lang={lang}
      />
    );
  };

  if (cardType === 'digital') {
    steps = [lang.giftcards.step1, lang.giftcards.step2, lang.giftcards.step3];
    stepsComponents = [
      getGiftCardOptionsStep,
      getGiftCardPaymentStep,
      getGiftCardSuccessStep,
    ];
  } else {
    steps = [
      lang.giftcards.step1,
      lang.giftcards.stepDelivery,
      lang.giftcards.step2,
      lang.giftcards.step3,
    ];
    stepsComponents = [
      getGiftCardOptionsStep,
      getGiftCardDeliveryStep,
      getGiftCardPaymentStep,
      getGiftCardSuccessStep,
    ];
  }

  return (
    <div className={selectContainerTheme(paramTheme)}>
      <div className='giftcards'>
        <ThemeProvider theme={theme}>
          <div className='header-container'>
            <div className='root'>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  return (
                    <Step key={label}>
                      <StepLabel>
                        {index === activeStep ? label : null}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>
            <button
              aria-label='button-close'
              id='close-widget'
              onClick={closeApp}
              className="on-stepper"
            >
              <Close fontSize='large' color='primary' />
            </button>
          </div>
          <div className='giftcard__body__container'>
            {stepsComponents[activeStep]()}
          </div>
          <StepFooter />
        </ThemeProvider>
      </div>
    </div>
  );
}
