import moment from "moment";
import styled from "styled-components";
import { StateUpdater, useEffect, useMemo, useState } from "preact/hooks";
import Loader from "../Loader";
import ButtonTime from "./OldButtonTime";
import { AvalibleTimes, Section } from "../types";
import { convertToLinkedText } from "../../helpers/convertToLinkedText";
import { isValidTime } from '../../helpers/isTimeGreaterThanNow';
import { mapSectionsFunction, validTimes } from '../../helpers/validTimes';

interface ButtonsSelectProps {
  setButtonTime: StateUpdater<string>;
  availableTimes: AvalibleTimes[];
  setButtonTimeEnd: StateUpdater<string>;
  guests: string;
  unitId: string;
  loadedTimes: boolean;
  setLoadedTimes: StateUpdater<boolean>;
  setRuleId: StateUpdater<string>
  activeButtonTime: string;
  setActiveButtonTime: StateUpdater<string>;
  setRecoupTime: StateUpdater<number>
}

export default function ButtonsSelect({
  setButtonTime,
  availableTimes,
  setButtonTimeEnd,
  guests,
  unitId,
  loadedTimes,
  setLoadedTimes,
  setRuleId,
  activeButtonTime,
  setActiveButtonTime,
  setRecoupTime,
}: ButtonsSelectProps) {
  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => {
    setLoadedTimes(true);
    setSections(mapSectionsFunction(availableTimes, unitId));
  }, [availableTimes, setLoadedTimes, unitId]);

  const validTimesArray = useMemo(
    () => validTimes(sections, guests),
    [sections, guests]
  );

  return (
    <div>
      {loadedTimes === false && <Loader />}
      {loadedTimes === true &&
        guests !== "maxGuests" &&
        sections.map((section) => {
          return (
            <div key={`unit${section.id}`}>
              <h2 className="step-title section">{section.name}</h2>
              {section.timeSets.length !== 0 &&
                section.timeSets.map((timeSet) => {
                  if (timeSet.isTextRow === true) {
                    const recoupTime =
                      timeSet.recoupTime !== undefined ? timeSet.recoupTime : 0;
                    return (
                      <div key={timeSet.id}>
                        {timeSet.title !== "" && (
                          <h3 className="section-title">{timeSet.title}</h3>
                        )}
                        {timeSet.times.map((time) => {
                          if (isValidTime(time, guests)) {
                            return (
                              <ButtonTime
                                setButtonTime={setButtonTime}
                                setButtonTimeEnd={setButtonTimeEnd}
                                time={time}
                                setRuleId={setRuleId}
                                timeSetId={time.id}
                                activeButtonTime={activeButtonTime}
                                setActiveButtonTime={setActiveButtonTime}
                                setRecoupTime={setRecoupTime}
                                recoupTime={recoupTime}
                                key={`unit${time.id}-${moment(
                                  time.start
                                ).format("HH:mm")}`}
                              />
                            );
                          }
                        })}
                        {timeSet.comment !== "" && (
                          <p className="step-text text-white">
                            {convertToLinkedText(timeSet.comment)}
                          </p>
                        )}
                      </div>
                    );
                    // eslint-disable-next-line no-else-return
                  } else {
                    for (let i = 0; i < timeSet.times.length; i++) {
                      const timeMap = timeSet.times[i];
                      if (
                        timeMap.availableSeats.includes(guests) &&
                        moment(timeMap.availableUntil).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        ) >=
                          moment()
                            .tz("Europe/Stockholm")
                            .format("YYYY-MM-DDTHH:mm:ss")
                      ) {
                        const recoupTime =
                          timeSet.recoupTime !== undefined
                            ? timeSet.recoupTime
                            : 0;

                        return (
                          <div key={timeSet.id}>
                            {timeSet.title !== "" && (
                              <h3 className="section-title">{timeSet.title}</h3>
                            )}
                            {timeSet.times.map((time) => {
                              if (isValidTime(time, guests)) {
                                return (
                                  <ButtonTime
                                    setButtonTime={setButtonTime}
                                    setButtonTimeEnd={setButtonTimeEnd}
                                    time={time}
                                    setRuleId={setRuleId}
                                    timeSetId={time.id}
                                    activeButtonTime={activeButtonTime}
                                    setActiveButtonTime={setActiveButtonTime}
                                    setRecoupTime={setRecoupTime}
                                    recoupTime={recoupTime}
                                    key={`unit${time.id}-${moment(
                                      time.start
                                    ).format("HH:mm")}`}
                                  />
                                );
                              }
                            })}
                            {timeSet.comment !== "" && (
                              <p className="step-text text-white">
                                {timeSet.comment}
                              </p>
                            )}
                          </div>
                        );
                      }
                    }
                  }
                })}
                {
                  validTimesArray.length>0 && <hr />
                }
            </div>
          );
        })}
    </div>
  );
}

const StyledPhoneNum = styled.a`
  color: #fff;
  margin: 0 !important;
`;
