import { StateUpdater, useEffect, useMemo, useState } from "preact/hooks";
import ButtonConfirm from "./OldButtonConfirm";
import ButtonsSelect from "./OldButtonsSelect";
import NativeSelects from "./OldNativeSelects";
import Loader from "../Loader";
import { Lang, AvalibleTimes, CaspecoHomeSettings, DayState } from '../types/index';
import { convertToLinkedText } from "../../helpers/convertToLinkedText";
import homeAPI from "../../services/homeAPI";
import { validTimes, mapSectionsFunction } from '../../helpers/validTimes';

interface Step1Props {
  lang: Lang;
  buttonTime: string;
  setButtonTime: StateUpdater<string>;
  activeStep: number;
  setActiveStep: StateUpdater<number>;
  guests: string;
  setGuests: StateUpdater<string>;
  dateBooking: string;
  setDateBooking: StateUpdater<string>;
  fromDate: string;
  toDate: string;
  unitId: string;
  system: string;
  setButtonTimeEnd: StateUpdater<string>;
  setBookPhone: StateUpdater<string>;
  setBookEmail: StateUpdater<string>;
  locale: string;
  availableTimes: AvalibleTimes[];
  setAvailableTimes: StateUpdater<AvalibleTimes[]>;
  setRuleId: StateUpdater<string>;
  setFromDate: StateUpdater<string>;
  setToDate: StateUpdater<string>;
  activeButtonTime: string;
  setActiveButtonTime: StateUpdater<string>;
  setRecoupTime: StateUpdater<number>;
  setDisplayMessageBox: StateUpdater<boolean>;
  paramPromo: string;
  caspecoSettings: CaspecoHomeSettings|undefined;
  dayStates: DayState[];
  setDayStates: StateUpdater<DayState[]>
}

export default function Step1({
  lang,
  setButtonTime,
  activeStep,
  setActiveStep,
  guests,
  setGuests,
  dateBooking,
  setDateBooking,
  fromDate,
  toDate,
  unitId,
  system,
  setButtonTimeEnd,
  setBookPhone,
  setBookEmail,
  locale,
  availableTimes,
  setAvailableTimes,
  setRuleId,
  setFromDate,
  setToDate,
  activeButtonTime,
  setActiveButtonTime,
  setRecoupTime,
  setDisplayMessageBox,
  paramPromo,
  caspecoSettings,
  dayStates,
  setDayStates
}:Step1Props) {
  const dateBookingParse = `${dateBooking.substring(0,19)}.000Z`;
  const dateBookingISO = useMemo(() => dateBookingParse, [dateBookingParse]);
  const [isLoadedAvailableTimes, setIsLoadedAvailableTimes] = useState(false);
  const [loadedTimes, setLoadedTimes] = useState(false);
  const [nameRestaurant, setNameRestaurant] = useState("");
  const [messageExceededGuests, setMessageExceededGuests] = useState("");
  const [maxGuests, setMaxGuests] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if(!firstLoad) {
      homeAPI.getDayStates(fromDate, toDate, unitId, paramPromo, system).then((data)=>{
        if(data) setDayStates(data)
      })
    }
  }, [fromDate, toDate, unitId]);

  useEffect(() => {
    if (!firstLoad) {
      homeAPI.getAvailableTimes(dateBookingISO, unitId , paramPromo, system)
      .then((data:any)=>{
        setAvailableTimes(data);
        setIsLoadedAvailableTimes(true);
      })
    } else {
      setFirstLoad(false);
      setIsLoadedAvailableTimes(true);
    }

  }, [dateBookingISO, unitId, setAvailableTimes]);

  const updateSettingsStates = (data:CaspecoHomeSettings) => {
    setBookPhone(data.phoneNumber);
    setBookEmail(data.email);
    setMessageExceededGuests(data.unitsMetaData.messageExceededGuests);
    setDisplayMessageBox(data.displayMessageBox);
    setGuests(activeButtonTime !== "" ? guests : data.defaultAmountGuests);
    setMaxGuests(data.unitsMetaData.maxWebGuests);
  }

  useEffect(() => {
    if (firstLoad && caspecoSettings){
      updateSettingsStates(caspecoSettings);
    } else {
      homeAPI.getCaspecoSettings(unitId, system).then ((data)=>{
        if (data) updateSettingsStates(data);
      })
    }
  }, [unitId]);

  const validTimesArray = useMemo(() => validTimes(
    mapSectionsFunction(availableTimes, unitId), guests),
  [availableTimes, unitId, guests]);

  return (
    <div>
      {isLoadedAvailableTimes === false && <Loader />}
      {isLoadedAvailableTimes && (
        <>
          <h1 className="step-title">{nameRestaurant}</h1>
          <NativeSelects
            guests={guests}
            setGuests={setGuests}
            dateBooking={dateBooking}
            setDateBooking={setDateBooking}
            dayStates={dayStates}
            availableTimes={availableTimes}
            lang={lang}
            locale={locale}
            setLoadedTimes={setLoadedTimes}
            maxGuests={maxGuests}
            setFromDate={setFromDate}
            setToDate={setToDate}
            setActiveButtonTime={setActiveButtonTime}
          />
          <ButtonsSelect
            setButtonTime={setButtonTime}
            availableTimes={availableTimes}
            setButtonTimeEnd={setButtonTimeEnd}
            unitId={unitId}
            guests={guests}
            loadedTimes={loadedTimes}
            setLoadedTimes={setLoadedTimes}
            setRuleId={setRuleId}
            activeButtonTime={activeButtonTime}
            setActiveButtonTime={setActiveButtonTime}
            setRecoupTime={setRecoupTime}
          />

          {
            loadedTimes &&  validTimesArray.length === 0 &&
            <p className="step-text">
              {caspecoSettings?.messageBookingClosed}
            </p>
          }

          { (loadedTimes && paramPromo !== 'amex_dining' && validTimesArray.length > 0)?
            <p className="step-text">
              {convertToLinkedText(messageExceededGuests)}
            </p>
            :
            null
          }
        </>
      )}
      <ButtonConfirm
        activeStep={activeStep}
        lang={lang}
        activeButtonTime={activeButtonTime}
        handleNext={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
        handleBack={() => {}}
      />
    </div>
  );
}
