import { StateUpdater, useEffect, useMemo, useState } from 'preact/hooks';
import Loader from './Loader';
import {
  Lang,
  AvalibleTimes,
  CaspecoHomeSettings,
  DayState,
} from './types/index';
import homeAPI from '../services/homeAPI';
import ButtonNext from './Button/ButtonNext';
import NativeSelects1 from './NativeSelects1';
import useBookingStore from '../store/bookingStore';
import { Steps } from './types/bookingSteps';

interface Step1Props {
  lang: Lang;
  buttonTime: string;
  dateBooking: string;
  setDateBooking: StateUpdater<string>;
  fromDate: string;
  toDate: string;
  unitId: string;
  system: string;
  setBookPhone: StateUpdater<string>;
  setBookEmail: StateUpdater<string>;
  locale: string;
  availableTimes: AvalibleTimes[];
  setAvailableTimes: StateUpdater<AvalibleTimes[]>;
  setFromDate: StateUpdater<string>;
  setToDate: StateUpdater<string>;
  setDisplayMessageBox: StateUpdater<boolean>;
  paramPromo: string;
  caspecoSettings: CaspecoHomeSettings | undefined;
  dayStates: DayState[];
  setDayStates: StateUpdater<DayState[]>;
}

export default function Step1({
  lang,
  dateBooking,
  setDateBooking,
  fromDate,
  toDate,
  unitId,
  system,
  setBookPhone,
  setBookEmail,
  locale,
  availableTimes,
  setAvailableTimes,
  setFromDate,
  setToDate,
  setDisplayMessageBox,
  paramPromo,
  caspecoSettings,
  dayStates,
  setDayStates,
}: Step1Props) {
  const { setActiveStep, guests, setGuests } = useBookingStore();
  const dateBookingParse = `${dateBooking.substring(0, 19)}.000Z`;
  const dateBookingISO = useMemo(() => dateBookingParse, [dateBookingParse]);
  const [isLoadedAvailableTimes, setIsLoadedAvailableTimes] = useState(false);
  const [maxGuests, setMaxGuests] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (!firstLoad) {
      homeAPI
        .getDayStates(fromDate, toDate, unitId, paramPromo, system)
        .then((data) => {
          if (data) setDayStates(data);
        });
    }
  }, [fromDate, toDate, unitId]);

  useEffect(() => {
    if (!firstLoad) {
      homeAPI
        .getAvailableTimes(dateBookingISO, unitId, paramPromo, system)
        .then((data: any) => {
          setAvailableTimes(data);
          setIsLoadedAvailableTimes(true);
        });
    } else {
      setFirstLoad(false);
      setIsLoadedAvailableTimes(true);
    }
  }, [dateBookingISO, unitId, setAvailableTimes]);

  const updateSettingsStates = (data: CaspecoHomeSettings) => {
    setBookPhone(data.phoneNumber);
    setBookEmail(data.email);
    setDisplayMessageBox(data.displayMessageBox);
    setGuests(guests === '' ? data.defaultAmountGuests : guests);
    setMaxGuests(data.unitsMetaData.maxWebGuests);
  };

  useEffect(() => {
    if (firstLoad && caspecoSettings) {
      updateSettingsStates(caspecoSettings);
    } else {
      homeAPI.getCaspecoSettings(unitId, system).then((data) => {
        if (data) updateSettingsStates(data);
      });
    }
  }, [unitId]);

  const handleNext = (e: any) => {
    e.preventDefault();
    setActiveStep(Steps.EventSelector);
  };

  return (
    <div className='steps-time-container'>
      {isLoadedAvailableTimes === false && <Loader />}
      {isLoadedAvailableTimes && (
        <>
          <NativeSelects1
            dateBooking={dateBooking}
            setDateBooking={setDateBooking}
            dayStates={dayStates}
            availableTimes={availableTimes}
            lang={lang}
            locale={locale}
            maxGuests={maxGuests}
            setFromDate={setFromDate}
            setToDate={setToDate}
          />
        </>
      )}
      <ButtonNext lang={lang} handleNext={handleNext} disabled={false} />
    </div>
  );
}
