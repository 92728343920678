import { FunctionalComponent, h } from 'preact';
import { TimeSet } from './types';
import moment from 'moment';
import ButtonTime from './Button/ButtonTime';
import { StateUpdater } from 'preact/hooks';
import { isValidTime } from '../helpers/isTimeGreaterThanNow';

interface Props {
  timeSet: TimeSet;
  guests: string;
  setButtonTime: StateUpdater<string>;
  setButtonTimeEnd: StateUpdater<string>;
  setRuleId: StateUpdater<string>;
  setRecoupTime: StateUpdater<number>;
}

const ButtonsTimesContainer: FunctionalComponent<Props> = ({
  timeSet,
  guests,
  setButtonTime,
  setButtonTimeEnd,
  setRuleId,
  setRecoupTime,
}) => {
  const recoupTime = timeSet.recoupTime !== undefined ? timeSet.recoupTime : 0;

  return (
    <div>
      {timeSet.times.map((time) => {
        if (isValidTime(time, guests)) {
          return (
            <ButtonTime
              setButtonTime={setButtonTime}
              setButtonTimeEnd={setButtonTimeEnd}
              time={time}
              setRuleId={setRuleId}
              timeSetId={time.id}
              setRecoupTime={setRecoupTime}
              recoupTime={recoupTime}
              key={`unit${time.id}-${moment(time.start).format('HH:mm')}`}
            />
          );
        }
      })}
    </div>
  );
};

export default ButtonsTimesContainer;
