export enum Steps {
  Bokaboard = 0, // Step0
  DateTimeSelector = 1, // StepTime1
  EventSelector = 1.5, // StepTime2
  EventTimes = 1.6, // StepTime3
  UserForm = 2, // Step2
  BookingSummary = 3, // Step3
  Payment = 4, // StepCardWithStripe
  Confirm = 5, // Step4
}
