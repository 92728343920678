import { makeStyles } from '@material-ui/core';
import { h } from 'preact';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 'auto',
    marginBottom: '15px',
    '& p': {
      marginRight: '5px',
    },
  },
}));

const MenuIcon = () => (
  <a
    href='https://www.maitres.com/'
    aria-label='Maitres-icon'
    target='_blank'
    rel='noreferrer'
  >
    <svg
      className='logo-icon'
      width='76'
      height='26'
      viewBox='0 0 76 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.3654 10.7887L11.6375 18.5417H13.251L14.4305 10.6792V18.5417H16.044V7.578H13.6704L12.5067 15.3623L11.2498 7.578H8.87561V18.5417H10.3654V10.7887Z'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M22.4529 9.5203L23.2752 15.0649H21.6306L22.4529 9.5203ZM21.4136 16.5527H23.508L23.8031 18.5414H25.5251L23.7872 7.57837H21.2892L19.5514 18.5414H21.1186L21.4136 16.5527Z'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M29.4478 18.542H31.1545V7.57837H29.4478V18.542Z'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M30.3011 5.95092L30.9991 6.81236H32.4577L31.0771 5.0741H29.541L28.1597 6.81236H29.6184L30.3011 5.95092Z'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M36.8144 18.5417H38.5212V9.14459H40.3053V7.578H35.0302V9.14459H36.8144V18.5417Z'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M45.5187 9.14435H46.3409C46.9462 9.14435 47.2096 9.48892 47.2096 10.2722V11.3527C47.2096 12.2295 46.8219 12.5113 46.1855 12.5113H45.5187V9.14435ZM45.5187 14.0779H46.1081C46.8841 14.0779 47.2254 14.4539 47.2254 15.4716V17.1945C47.2254 18.0873 47.2876 18.2596 47.3802 18.5414H49.1181C48.9474 18.1347 48.9322 17.7427 48.9322 17.2105V15.519C48.9322 14.3757 48.653 13.5451 47.7996 13.1851C48.5597 12.8245 48.9163 12.0886 48.9163 10.9608V10.0999C48.9163 8.40843 48.1562 7.57776 46.3873 7.57776H43.8119V18.5414H45.5187V14.0779Z'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M57.4657 16.9754H54.5179V13.7647H56.8604V12.1988H54.5179V9.14434H57.4657V7.57837H52.8111V18.542H57.4657V16.9754Z'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M63.6755 18.667C65.3512 18.667 66.2198 17.6646 66.2198 15.9104C66.2198 12.7778 62.8843 12.3551 62.8843 10.1C62.8843 9.31667 63.1946 9.01885 63.7377 9.01885C64.2808 9.01885 64.5911 9.31667 64.5911 10.1V10.5541H66.2046V10.2095C66.2046 8.45523 65.3512 7.45288 63.6908 7.45288C62.0309 7.45288 61.1775 8.45523 61.1775 10.2095C61.1775 13.342 64.5137 13.7648 64.5137 16.0199C64.5137 16.8032 64.1717 17.085 63.6292 17.085C63.0861 17.085 62.7447 16.8032 62.7447 16.0199V15.2372H61.1306V15.9104C61.1306 17.6646 61.9998 18.667 63.6755 18.667Z'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M1.70798 24.0233H73.3939V2.09593H1.70798V24.0233ZM75.0958 0.372437H0V25.7474H1.70737H73.3939H73.6999H75.1012V0.372437H75.0958Z'
      />
    </svg>
  </a>
);

export default function StepFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p>POWERED BY</p> <MenuIcon />
    </div>
  );
}
