import {
  ListItemIcon,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { h } from 'preact';
import PersonIcon from '@material-ui/icons/Person';
import MaterialUIPickers from './MaterialUIPickers';
import { StateUpdater, useMemo } from 'preact/hooks';
import { Day, Lang, AvalibleTimes } from './types/index';
import TimeSelector from './TimeSelector/TimeSelector';
import useBookingStore from '../store/bookingStore';
import { Steps } from './types/bookingSteps';

interface NativeSelectProps {
  dateBooking: string;
  setDateBooking: StateUpdater<string>;
  dayStates: Day[];
  availableTimes: AvalibleTimes[];
  lang: Lang;
  locale: string;
  maxGuests: number;
  setFromDate: StateUpdater<string>;
  setToDate: StateUpdater<string>;
  unitId: string;
  setButtonTime: StateUpdater<string>;
  setButtonTimeEnd: StateUpdater<string>;
  setRuleId: StateUpdater<string>;
}

export default function NativeSelects({
  dateBooking,
  setDateBooking,
  dayStates,
  availableTimes,
  lang,
  locale,
  maxGuests,
  setFromDate,
  setToDate,
  unitId,
  setButtonTime,
  setButtonTimeEnd,
  setRuleId,
}: NativeSelectProps) {
  const { setActiveButtonTime, activeStep, guests, setGuests } =
    useBookingStore();

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      gap: activeStep !== Steps.EventTimes ? '10px' : '',
      justifyContent: 'space-between',
      position: 'relative',
      '& .MuiTextField-root': {
        // margin: theme.spacing(1),
        width: activeStep === Steps.EventSelector ? '110px' : '152px',
      },
      '&': {
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.black,
          position: 'absolute',
          top: '15px',
          left: '5px',
        },
        '& .MuiSelect-root': {
          paddingLeft: '35px',
        },
        '& .MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  }));
  const classes = useStyles();

  const guestsMassive = useMemo(() => {
    const guests = [];

    for (let index = 0; index < maxGuests; index++) {
      guests.push({ value: index + 1, label: index + 1 });
    }

    guests.push({
      value: `maxGuests`,
      label: lang.start.moreThanMaxWebGuests + maxGuests,
    });
    return guests;
  }, [lang, maxGuests]);

  const handleChange = (event: any) => {
    setGuests(event.target!.value);
    setActiveButtonTime('');
  };

  return (
    <div className={classes.root}>
      <ListItemIcon>
        <PersonIcon fontSize='medium' color='primary' />
      </ListItemIcon>

      <TextField
        id='outlined-select-currency'
        select
        label={lang.guests}
        value={guests}
        onChange={handleChange}
        variant='outlined'
        className={classes.root}
        required
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        }}
      >
        {guestsMassive.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <MaterialUIPickers
        dateBooking={dateBooking}
        setDateBooking={setDateBooking}
        dayStates={dayStates}
        availableTimes={availableTimes}
        lang={lang}
        locale={locale}
        setFromDate={setFromDate}
        setToDate={setToDate}
        setActiveButtonTime={setActiveButtonTime}
      />
      {activeStep === Steps.EventSelector && (
        <TimeSelector
          dateBooking={dateBooking}
          availableTimes={availableTimes}
          guests={guests}
          setButtonTime={setButtonTime}
          setButtonTimeEnd={setButtonTimeEnd}
          unitId={unitId}
          setRuleId={setRuleId}
        />
      )}
    </div>
  );
}
