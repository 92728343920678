import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment-timezone";
import disabledTimeZone from '../../helpers/disabledTimeZone';
import LabelWithLink from "../LabelWithLink";
import getCustomMessage from '../../helpers/customeMessage';
import { Lang } from '../types/index';
import constants from "../../constants";
import CustomMessage from "../Home/CustomMessage";


const date = new Date();
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTypography-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "15px",
    },
    "& .booking-date": {
      fontSize: "1.5rem",
    },
    "& .box-summary.last": {
      marginBottom: '1.75rem',
    },
  },
}));

interface Step4Props {
  system?: string;
  unitId?: string;
  buttonTime: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  guests: string;
  dateBooking: string;
  buttonTimeEnd: string;
  bookEmail: string;
  bookPhone: string;
  globalBookingNumber: string;
  lang: Lang;
  recoupTime?: number;
  skipped: string[]
  selectedParamNoShow: any
}

export default function Step4({
  system='',
  unitId='',
  buttonTime,
  firstName,
  lastName,
  phone,
  email,
  guests,
  dateBooking,
  buttonTimeEnd,
  bookEmail,
  bookPhone,
  globalBookingNumber,
  lang,
  recoupTime,
  skipped,
  selectedParamNoShow,
}: Step4Props) {
  const classes = useStyles();
  const dateTime = moment.utc(dateBooking).tz(constants.TIMEZONE).format("YYYY-MM-DD");
  const timeBookingDuration = moment(disabledTimeZone(buttonTime)).format("H:mm");
  const timeBookingEnd = moment(disabledTimeZone(buttonTimeEnd))
    .subtract(recoupTime, "minutes")
    .format("H:mm");
  const toSendData = skipped.includes(lang.stepsEnum[900]);

  /**
   * Compares datetime now between limits, this are config in constant file.
   * @returns True if datetime now is between limits.
   */
  return (
    <div className={classes.root}>
      <h2 className="step-title">{lang.thanks}</h2>
      <div className="infoBox">
        <div>
          <Typography>{lang.stepsEnum["100"]}</Typography>
          <Typography className="booking-date">
            <CalendarTodayIcon fontSize="medium" color="primary" />
            {dateTime} {timeBookingDuration} - {timeBookingEnd}
          </Typography>
        </div>
        <div>
          <Typography>{lang.bookingNumber}</Typography>
          <Typography className="booking-date">
            {globalBookingNumber || "NA"}
          </Typography>
        </div>
      </div>
      <div className="box-summary">
        <div className="box">
          <h6>{lang.contact.name}</h6>
          <p>
            {firstName} {lastName}
          </p>
        </div>
        <div className="box">
          <h6>{lang.contact.mobile}</h6>
          <p>{phone}</p>
        </div>
        <div className="box">
          <h6>{lang.contact.email}</h6>
          <p>{email}</p>
        </div>
        <div className="box">
          <h6>{lang.guests}</h6>
          <p>{guests}</p>
        </div>
      </div>
      <div className={!toSendData  && !getCustomMessage(system, unitId, lang) ? 'box-summary' : 'box-summary last'}>
        <div className="box ours">
          <h6>{lang.contact.email}</h6>
          <a href={`mailto:${bookEmail}`}>{bookEmail}</a>
        </div>
        <div className="box ours">
          <h6>{lang.contact.mobile}</h6>
          <a href={`tel:${bookPhone}`}>{bookPhone}</a>
        </div>
      </div>
      {!toSendData &&  !(system == 'se_skegam' && unitId == '13') && (
        <div className="box-summary">
          <div className="box">
            <p>{selectedParamNoShow?.amount_per_person * Number(guests)} SEK eventuell No show-avgift</p>
          </div>
        </div>
      )}
      <CustomMessage system={system} unitId={unitId} lang={lang} />
      <hr />
      <LabelWithLink
        label={(date.getMonth() + 1) === 9 ? lang.confirmation.labelSeptember : lang.confirmation.labelApp}
        url={lang.confirmation.labelAppLink}
        isTargetBlank={true}
      />
    </div>
  );
}
