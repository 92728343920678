import { h } from 'preact';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ListItemIcon, makeStyles } from '@material-ui/core';
import { StateUpdater, useMemo, useEffect, useState } from 'preact/hooks';
import constants from '../../constants';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { AvalibleTimes, Lang } from './../types';
import { Day } from './../types/index';
import 'moment/dist/locale/sv';

moment.locale('sv');

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'inline-block',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '150px',
    },
    '&': {
      '& .MuiListItemIcon-root': {
        color: theme.palette.common.black,
        position: 'absolute',
        top: '24px',
        left: '20px',
      },
      '& .MuiInputBase-root': {
        paddingLeft: '30px',
      },
    },
  },
}));

interface Locales {
  [key: string]: string;
}

const localeMap: Locales = {
  en: 'en',
  sv: 'sv',
};

interface MaterialUIPickersProps {
  dateBooking: string;
  setDateBooking: StateUpdater<string>;
  dayStates: Day[];
  availableTimes: AvalibleTimes[];
  lang: Lang;
  locale: string;
  setLoadedTimes: StateUpdater<boolean>;
  setFromDate: StateUpdater<string>;
  setToDate: StateUpdater<string>;
  setActiveButtonTime: StateUpdater<string>;
}

export default function MaterialUIPickers({
  dateBooking,
  setDateBooking,
  dayStates,
  availableTimes,
  lang,
  locale,
  setLoadedTimes,
  setFromDate,
  setToDate,
  setActiveButtonTime,
}: MaterialUIPickersProps) {
  useEffect(() => {
    moment.locale(locale);
  }, [locale]);

  const classes = useStyles();
  const [disableAll, setDisableAll] = useState(false)

  const disabledDates = useMemo(() => {
    setDisableAll(false)
    const disabledDays = dayStates.filter((day) => day.state <= 1);
    return disabledDays.map((day) => moment(day.day).toISOString());
  }, [dayStates]);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    const naiveDate = moment(date).format('YYYY-MM-DD HH:mm:ss');

    if (
      moment(dateBooking).tz(constants.TIMEZONE).format('YYYY-MM-DD') !==
      moment(date).format('YYYY-MM-DD')
    ) {
      setActiveButtonTime('');
      setDateBooking(moment.tz(naiveDate, constants.TIMEZONE).format());
      setLoadedTimes(false);
    }
  };

  const disableDaysWithNoBookings = (date: MaterialUiPickersDate) => {
    const dates = moment(date).toISOString();
    return disableAll || disabledDates.includes(dates);
  };

  const onMonthChange = (date: MaterialUiPickersDate) => {
    setDisableAll(true)
    const startOfMonth = moment(date)
      .tz(constants.TIMEZONE)
      .format('YYYY-MM-DD');
    const endOfMonth = moment(date)
      .tz(constants.TIMEZONE)
      .endOf('month')
      .format('YYYY-MM-DD');

    setFromDate(startOfMonth);
    setToDate(endOfMonth);
  };

  const aux = moment(dateBooking)
    .tz(constants.TIMEZONE)
    .format('YYYY-MM-DD HH:mm:ss');

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={localeMap[locale]}
      >
        <ListItemIcon>
          <CalendarTodayIcon fontSize='medium' color='primary' />
        </ListItemIcon>
        <DatePicker
          variant='inline'
          inputVariant='outlined'
          label={lang.date}
          value={moment
            .tz(aux, constants.TIMEZONE)
            .format('YYYY-MM-DD HH:mm:ss')}
          onChange={handleDateChange}
          shouldDisableDate={disableDaysWithNoBookings}
          minDate={moment().tz(constants.TIMEZONE).format('YYYY-MM-DD HH:mm')}
          format='MMM DD'
          error={availableTimes.length === 0}
          disableCloseOnSelect={false}
          onMonthChange={onMonthChange}
          autoOk={true}
          required
          timeZone={constants.TIMEZONE}
          onClose={() => {
            const startOfMonth = `${moment().clone().tz(constants.TIMEZONE).startOf("month").format("YYYY-MM-DD")}T00:00:00.000Z`;
            const endOfMonth = `${moment().clone().tz(constants.TIMEZONE).add(1, "month").endOf("month").format("YYYY-MM-DD")}T00:00:00.000Z`;
            setFromDate(startOfMonth);
            setToDate(endOfMonth);
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
