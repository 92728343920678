import { FunctionComponent, h } from "preact";
import Button from '@material-ui/core/Button';
import MaitresSimbol from './MaitresIcon/MaitresSimbol';
import Typography from '@material-ui/core/Typography';
import useBookingStore from '../store/bookingStore';
import { Steps } from './types/bookingSteps';

interface BokaBoardButton {
}

const Step0: FunctionComponent<BokaBoardButton> = ({}) => {
  const { setActiveStep } = useBookingStore()

  const onInitBooking = () => {
    setActiveStep(Steps.DateTimeSelector);
  }
  return (
    <div>
      <Button
        style={{
          pl: '17px',
          pr: '25px',
          height: '42px',
          textTransform: 'uppercase',
          color: '#ffffff',
          boderColor: 'rgba(255, 255, 255, 0.4)',
          background: 'rgba(0, 0, 0, 0.35)',
          display: 'flex',
          whiteSpace: 'nowrap',
        }}
        variant='outlined'
        onClick={onInitBooking}
      >
        <MaitresSimbol width='22' height='22'/>
        <Typography style={{ fontSize: '13px', marginLeft: "12px" }}>
          Boka bord
        </Typography>
      </Button>
    </div>
  )
}

export default Step0;
